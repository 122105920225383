"use client";

import { Icons } from "../icons";
import HomeSearchImage from "./home-search-image";
import useItineraryQuestionFormV3Store from "@/app/(ai-planning)/tao-lich-trinh/hooks/itinerary-question-form-v3-store";
import { sendEventGA } from "@/lib/google/google-analytics";
import dynamic from "next/dynamic";
import Head from "next/head";
import Link from "next/link";
import { useEffect, useState } from "react";

const HomeSearchInput = dynamic(
  () => import("@/app/(main)/home/components/home-search-input"),
  {
    ssr: false,
  }
);

const tabItems = [
  {
    code: "create-iti",
    text: "✏️ Chat with Laka.AI",
  },
  {
    code: "services",
    text: "🏖 Hoạt động & Vui chơi",
  },
];

const TabItem = ({ code, text, active = false, onClick }) => {
  return (
    <div
      key={code}
      className={`relative flex min-h-14 w-1/2 cursor-pointer items-center rounded-xl px-6 py-3 text-center text-xs font-semibold md:min-h-10 md:rounded-full md:py-2 md:text-base lg:text-nowrap ${
        active ? "bg-white text-secondary-100" : " bg-transparent text-white"
      }`}
      onClick={() => {
        handleScrollSearchBox();
        onClick(code);
      }}
    >
      <div>{text}</div>
      {code === "services" && (
        <span className="absolute inset-x-0 -top-3 mx-auto w-fit rounded-full rounded-bl-none bg-[#F25800] px-2 py-[3px] text-[10px] font-semibold leading-3 text-white md:-top-5 md:py-1 md:text-xs">
          Ưu đãi đến 40%
        </span>
      )}
    </div>
  );
};

const TabWrap = ({ tabActive, handActiveTab }) => {
  return (
    <div className="px-6">
      <div
        className="relative z-[1] flex w-full max-w-2xl flex-row items-center justify-center rounded-xl bg-secondary-100 p-1 md:rounded-full md:py-1.5"
        style={{
          boxShadow: "0px 4px 20px 0px #0000001A",
        }}
      >
        {tabItems.map((item) => (
          <TabItem
            code={item.code}
            text={item.text}
            active={tabActive === item.code}
            onClick={handActiveTab}
          />
        ))}
      </div>
    </div>
  );
};

export const handleScrollSearchBox = () => {
  if (typeof window === "undefined") return;
  const currentlyDesktop = window.innerWidth >= 768;

  const bodyRef = document.getElementById("body-home-search");
  if (bodyRef && !currentlyDesktop) {
    const elementPosition =
      bodyRef.getBoundingClientRect().top + window?.scrollY;

    if (elementPosition > 40) {
      setTimeout(() => {
        bodyRef.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 1);
    }
  }
};

const HomeSearch = () => {
  const [tabActive, setTabActive] = useState("create-iti");
  const [expanded, setExpanded] = useState(false);
  const { reset } = useItineraryQuestionFormV3Store();

  useEffect(() => {
    reset();
  }, []);

  const handActiveTab = (key) => {
    setExpanded(false);
    setTabActive(key);
    if (key === "services") {
      sendEventGA("hp_ecom_tab_click");
    }
  };

  const handleExpanded = () => {
    setExpanded(true);
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href="/images/home/block-right-2x.webp"
        />
      </Head>
      <div className="relative z-10 flex flex-col justify-center gap-8">
        <div className="container grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-0">
          <div className="flex flex-col gap-7 md:gap-[34px] md:py-8 md:pl-2">
            <div className="flex flex-col items-center justify-center gap-2 pt-6 md:pt-8">
              <h1
                className="text-center text-lg font-bold text-white md:text-[28px] md:leading-10"
                style={{
                  textShadow: "0px 1px 4px rgba(0, 0, 0, 0.18)",
                }}
              >
                Tạo lịch trình du lịch miễn phí,
                <br /> trải nghiệm hoạt động giá ưu đãi!
              </h1>
            </div>
            <div className="">
              <div
                id="body-home-search"
                className="inset-x-0 top-0 flex w-full flex-col items-center px-4 pt-4 md:relative md:px-0"
              >
                <TabWrap tabActive={tabActive} handActiveTab={handActiveTab} />
                <div
                  className="-mt-9 flex w-full flex-col items-center rounded-[20px] bg-[#FFFFFFCC] px-4 pb-6 md:-mt-9 md:px-8 md:pb-10"
                  style={{
                    boxShadow: "0px 4px 40px 0px #3C64DB38",
                  }}
                >
                  <div className="h-16 items-center" />
                  <div className="flex w-full justify-center">
                    <div className="w-full max-w-2xl">
                      {tabActive === "create-iti" ? (
                        <div className="flex w-full flex-col items-center md:pt-3.5">
                          <Link href={'/chat'} className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-full bg-tertiary-50 px-6 py-3.5 text-white md:w-auto">
                            <div>
                              <Icons.message className="size-5 md:size-7" />
                            </div>
                            <div className="text-center text-base font-semibold leading-normal">
                              Start chat with Laka.AI
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <HomeSearchInput setExpanded={handleExpanded} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HomeSearchImage />
        </div>
      </div>
    </>
  );
};

export default HomeSearch;
