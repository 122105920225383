"use client";

import { Icons } from "@/components/icons";
import React, { useEffect, useRef, useState } from "react";

interface LayoutScrollProps {
  children: React.ReactElement;
  alwaysShowArrow?: boolean;
  centerMode?: boolean;
  alwaysShowArrowMobile?: boolean;
  showArrow?: boolean;
  moveAmount?: number;
  id?: string;
  onScrollEnd?: () => void;
  classNames?: {
    container?: string;
    width?: string;
    iconPosition?: string;
    wrap?: string;
  };
}
const SlideImage: React.FC<LayoutScrollProps> = ({
  children,
  showArrow = true,
  alwaysShowArrow = false,
  alwaysShowArrowMobile = false,
  moveAmount = 150,
  centerMode = false,
  id = "image-scroll-container",
  onScrollEnd,
  classNames = {
    container: "",
    width: "",
    iconPosition: "",
    wrap: "",
  },
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);
  const [isAtStart, setIsAtStart] = useState<boolean>(true);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isAtEnd && onScrollEnd) {
      onScrollEnd();
    }
  }, [isAtEnd]);

  useEffect(() => {
    handleScroll();
  }, [children]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
      setIsAtStart(scrollLeft === 0);
    }
  };

  const handleScrollLeft = () => {
    if (scrollRef.current && containerRef.current) {
      scrollRef.current.scrollBy({
        left: -containerRef.current.offsetWidth + moveAmount,
        behavior: "smooth",
      });
    }
  };
  const handleScrollRight = () => {
    if (scrollRef.current && containerRef.current) {
      scrollRef.current.scrollBy({
        left: containerRef.current.offsetWidth - moveAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (centerMode) {
      if (scrollRef.current && containerRef.current) {
        console.log(containerRef.current.offsetWidth,"TScontainerRef.current.offsetWidth11");
        
        scrollRef.current.scrollBy({
          left: 155,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const handleToggle = (hoverState: boolean) => {
    if (!alwaysShowArrow) {
      setIsHovered(hoverState);
    }
  };

  return (
    <div
      ref={containerRef}
      className={classNames?.width}
      onMouseEnter={() => handleToggle(true)}
      onMouseLeave={() => handleToggle(false)}
    >
      <div className={"relative flex flex-col items-start justify-center"}>
        <div className="relative grid w-full grid-cols-1 justify-stretch rounded-2xl">
          <div
            id={id}
            ref={scrollRef}
            className={
              "no-scrollbar inline-flex w-full items-start justify-start overflow-x-scroll gap-px rounded-lg" +
              classNames.wrap
            }
          >
            {children}
          </div>

          {showArrow && (alwaysShowArrow || isHovered) && !isAtStart && (
            <div
              onClick={handleScrollLeft}
              className={`absolute left-6 top-1/2 size-7 -translate-x-1/2 -translate-y-1/2 items-center justify-center border border-secondary-60 bg-white md:flex md:size-10 xl:right-0 ${
                isAtStart ? " text-[#D8DAE2]" : " text-secondary-100 "
              } ${
                alwaysShowArrowMobile ? " flex " : " hidden"
              } inline-flex cursor-pointer items-center justify-center gap-2 rounded-3xl`}
            >
              <Icons.arrowLeft
                color={isAtStart ? "#D8DAE2" : "#111B42"}
                className="relative  size-5 hover:text-white md:size-6"
              />
            </div>
          )}
          {showArrow && (alwaysShowArrow || isHovered) && !isAtEnd && (
            <div
              onClick={handleScrollRight}
              className={`absolute right-6 top-1/2 size-7 -translate-y-1/2 translate-x-1/2 items-center justify-center border border-secondary-60 bg-white md:flex md:size-10 xl:right-0 ${
                isAtEnd ? " text-[#D8DAE2]" : " text-secondary-100"
              } ${
                alwaysShowArrowMobile ? " flex " : " hidden"
              }  inline-flex cursor-pointer items-center justify-center gap-2 rounded-3xl`}
            >
              <Icons.arrowRight className="relative size-5 md:size-6" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlideImage;
